import Vue from 'vue'
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'

Vue.use(VModal, {
  dynamicDefaults: {
    name: 'vue-modal',
    draggable: false,
    resizable: false,
    adaptive: true,
    scrollable: true,
    minWidth: 700,
    minHeight: 600,
    height: 'auto'
  }
})
